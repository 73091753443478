import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchMappingLocationList(params) {
  return axios.get(baseUrl + baseApiVersion + `/mapping/location/list`, {
    headers,
    params,
  });
}

