import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";


export function fetchMappingCreate(data) {
    return  axios.post(baseUrl + baseApiVersion + `/mapping`, data, { headers });
  }

  export function fetchMappingList(params) {
    return axios.get(baseUrl + baseApiVersion + `/mapping/list`, {
      headers,
      params,
    });
  }

  export function fetchMappingInfo(mappingId) {
    return axios.get(baseUrl + baseApiVersion + `/mapping/${mappingId}`, {
      headers,
    });
  }

  
export function fetchMappingUpdate(data) {
  return  axios.put(baseUrl + baseApiVersion + `/mapping/update`, data, { headers });
}

  export function fetchMappingDelete(mappingId) {
    return axios.delete(baseUrl + baseApiVersion + `/mapping/${mappingId}`, {
      headers,
    });
  }