<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-title">
        <h2>비행 안전 책임 안내</h2>
      </div>
      <div class="modal-content">
        <h2>1.비행 전 점검 책임: "모든 비행 전 점검, 경로 계획 및 공역 준수 여부는 조종자의 단독 책임입니다. </h2>
        <h2>2.운항 책임: "사용자는 생성된 KML 비행 경로를 이용한 모든 자동 비행의 안전성에 대한 책임을 전적으로 부담함을 인정합니다.</h2>
        <h2>3.사고 발생 시 책임: "본 시스템을 통해 비행을 실행하여 발생하는 사고나 법적 문제에 대해선 비행 경로 생성자 또는 비행을 실행하는 조종자에게 책임이 있습니다.</h2>
      </div>
      <div class="modal-checkbox">
        <h2>동의합니다.</h2>
        <input type="checkbox" v-model="allow"/>
      </div>
      <div class="modal-btn">
        <button @click="saveModal" class="btn-save">확인</button>
        <button @click="closeModal">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoFlightLiabilityModal",
  // props: {
  //   type: {
  //     type: String,
  //     required: true
  //   },
  // },
  data() {
    return {
       allow : false
    }
  },
  created() {
    console.log("created AutoFlightLiabilityModal")
  },
  mounted() {

  },
  methods: {
    closeModal() {
      //this.$emit('isStationModal', false)
      this.$emit('isShowModal', false)
    },
    saveModal() {
      console.log("allow?" , this.allow)
      if(this.allow){
        this.$emit('isShowModal', false)
        this.$emit('sendAllow', this.allow)
      }else{
        alert("동의 후 확인을 눌러주세요.")
      }
    },
    settingAllow(check){
      this.allow = check
    }

  },
}
</script>

<style scoped>

.modal-wrapper {
  width: 600px;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.modal-checkbox{
  display: flex;
  align-items: center; /* 세로로 가운데 정렬 */
  gap: 10px; /* 제목과 체크박스 사이 간격 */
  border: 1px solid #ddd;
  padding: 8px 10px;
  margin-top: 10px;
  border-radius: 3px;
}
.modal-checkbox > h2{
  margin-bottom: 0;
  line-height: 12px;
}
.modal-checkbox > input[type=checkbox]{
  margin: 0;
}
.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.modal-title > h2{
  font-size: 1.6rem;
  font-weight: bold;
}
.modal-content {
  display: flex;
  flex-direction: column; /* 세로로 나열되도록 설정 */
  align-items: flex-start; /* 왼쪽 정렬 */
  justify-content: flex-start; /* 상단 정렬 */
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.modal-btn {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.modal-btn>button {
  margin: 0 5px;
  width: 120px;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #969696;
  font-size: 1.4rem;
}

.modal-btn>button.btn-save {
  background: #0080ff;
  color: #fff;
  border: 1px solid #0080ff;
}

</style>